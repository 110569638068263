import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { My7nEvaluationModule } from './my7n-evaluation-module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// @ts-ignore
const isIE = window.document && !!window.document.documentMode;

if (!isIE) {
  platformBrowserDynamic()
    .bootstrapModule(My7nEvaluationModule)
    .catch((err) => console.error('[bootstrapModule] error occurred', err));
} else {
  console.error(
    '[bootstrapModule] Internet Explorer detected, App won\'t start'
  );
}
