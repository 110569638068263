<sub-navbar></sub-navbar>
<div class="step-container">
  <form #questionForm="ngForm" [formGroup]="stepForm" (ngSubmit)="onSubmit()" [ngClass]="{'submitted': formSubmitAttempt}">
    <input formControlName="questionId" type="hidden" />
    <div class="step-content step{{currentStep}} flex flex-col">
        <div class="eval-step-title">{{title}}</div>
        <div class="eval-question">{{question}}
          <tooltip-popover position="bottom" #tooltip>
            <mat-icon-with-hover #tooltipIcon iconName="tooltip_default" iconNameHover="tooltip_hover" iconNameActive="tooltip_active"></mat-icon-with-hover>
            <ng-template cdkPortal #tooltipPopoverContent="cdkPortal" let-tooltipPopover>
              <mat-icon svgIcon="close_tiny" (click)="tooltip.closePopover()" class="close-icon"></mat-icon>
              <div class="multiline-content">
                <strong>Did the consultant present:</strong>
                <ul>
                  <li *ngFor="let skill of desiredSkills">{{skill}}?</li>
                </ul>
              </div>
            </ng-template>
          </tooltip-popover>
        </div>
        <validation-messages class="error" [field]="stepForm.get('evalAnswer')" [messages]="['required']" [texts]="{required:'Please provide the required field(s) to continue to the next step.'}"></validation-messages>
        <div class="eval-answers flex flex-col">
          <mat-radio-group formControlName="evalAnswer" class="flex flex-col sm:flex-row flex-between-stretch" color="primary">
            <mat-radio-button id="eval-answer1" [value]="1">Strongly disagree</mat-radio-button>
            <mat-radio-button id="eval-answer2" [value]="2">Disagree</mat-radio-button>
            <mat-radio-button id="eval-answer3" [value]="3">Neutral</mat-radio-button>
            <mat-radio-button id="eval-answer4" [value]="4">Agree</mat-radio-button>
            <mat-radio-button id="eval-answer5" [value]="5">Strongly agree</mat-radio-button>
          </mat-radio-group>
          <hr />
        </div>
        <div class="eval-answer-comment">
          <mat-form-field appearance="outline" floatLabel="always" class="textarea-input w-full">
            <mat-label>Leave feedback (optional)</mat-label>
            <textarea formControlName="feedback"
                      id="eval-answer-feedback"
                      matInput
                      placeholder="Please comment on any specific observations that have been important for your score. (Max. {{feedbackMaxLength}} chars)"
                      [maxlength]="feedbackMaxLength"></textarea>
          </mat-form-field>
        </div>
    </div>
  </form>
</div>
