import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EvaluationDataService } from '../services/evaluation-data.service';
import { ResolveDecorator } from '../classes/resolve-decorator';
import { IEvaluationData } from '../interfaces/evaluation-data';

@Injectable()
export class EvaluationDataResolver implements Resolve<any> {
  constructor(private evaluationDataService: EvaluationDataService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.evaluationDataService.getEvaluationData(route.firstChild.params.evalId).then((evalData: IEvaluationData) => {
      if (evalData.IsCompleted === true) {
        return this.router.navigateByUrl('/evaluation/already-finished');
      }
    }).catch((error) => {
      console.error('[EvaluationDataResolver] There was a problem while getting evaluation details id=',
        route.firstChild.params.evalId, error);
      ResolveDecorator.redirectToErrorPage(error, this.router);
      return Promise.reject(error);
    });
  }
}
