import {HttpErrorResponse} from "@angular/common/http";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

export class ResolveDecorator {
  constructor() {}

  public static handleError(error: HttpErrorResponse, router: Router) {
    console.log('[ResolveDecorator] handleError');
    const sub = router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        console.log('[ResolveDecorator] handleError onetime redirect subscribe', event);
        ResolveDecorator.redirectToErrorPage(error, router);
        sub.unsubscribe();
      });
  }

  public static redirectToErrorPage(error: HttpErrorResponse, router: Router) {
    console.warn('redirectToErrorPage', error);
    switch (error.status) {
      case 500:
      case 403:
      case 401:
        console.debug(`[ResolveDecorator] Redirecting to error ${error.status} component, without location change`);
        router.navigateByUrl('/evaluation/server-error');
        break;
      case 410:
        console.debug(`[ResolveDecorator] Redirecting to error ${error.status} component, without location change`);
        router.navigateByUrl('/evaluation/expired');
        break;
      case 404:
      case 400:
        console.debug(`[ResolveDecorator] Redirecting to error ${error.status} component, without location change`);
        router.navigateByUrl('/evaluation/not-found');
        break;
    }
  }
}
